export let instance = null;
export function registerWebView(webview) {
    instance = webview;
}
// les applications <= v2.0.5 sont buildées avec la fonction ci-dessous :
//
// export function initWebView() {
//  return `window.nativeApp = true;`
// }
export function initWebView() {
    return { nativeApp: true };
}
export function isNativeApp() {
    try {
        const jsonObject = JSON.parse(window.ReactNativeWebView.injectedObjectJson());
        return jsonObject.nativeApp === true;
    }
    catch (e) {
        // rétrocompatibilité avec "return `window.nativeApp = true;`;" pour les applications <= v2.0.5
        return window.nativeApp === true;
    }
}
export function isWebApp() {
    try {
        const jsonObject = JSON.parse(window.ReactNativeWebView.injectedObjectJson());
        return jsonObject.nativeApp === undefined;
    }
    catch (e) {
        // rétrocompatibilité avec "return `window.nativeApp = true;`;" pour les applications <= v2.0.5
        return window.nativeApp === undefined;
    }
}
export function getInsets() {
    return window.insets;
}
export function subscribeToNative(listener) {
    const cb = (message) => {
        try {
            return listener(JSON.parse(message.data));
        }
        catch (e) {
            console.error(e);
        }
    };
    window.addEventListener("message", cb);
    return cb;
}
export function unsubscribeToNative(cb) {
    if (isNativeApp()) {
        window.removeEventListener("message", cb);
    }
}
export function subscribeToApp(listener, loadedCallback) {
    return (event) => {
        const data = event.nativeEvent.data;
        const message = JSON.parse(data);
        listener(message, loadedCallback);
    };
}
export function sendToNative(message) {
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
}
export function sendToApp(message) {
    const json = JSON.stringify(message);
    const script = `window.postMessage(\`${json}\`); true;`;
    if (instance === null) {
        throw new Error("WebView must be registered prior to sending messages");
    }
    instance.injectJavaScript(script);
}
