import * as Config from "configuration";
import * as Auth from "oauth2";

export async function fetchMenu(noEcole?: number) {
  const api = Config.get("apiCarnetRouge");
  const url = `${api}/api/carnet-rouge/navigation`;
  const qs = new URLSearchParams();
  if (noEcole) qs.set("noEcole", String(noEcole));

  return await Auth.fetchMaybeAuthenticated<Menu>(url + "?" + qs.toString());
}
