import * as React from "react";
import * as Auth from "oauth2";
import * as Format from "format";
import * as App from "global-state";
import * as Api from "../services/api";
import * as Router from "react-router-dom";
import * as Query from "react-query";
import * as Broker from "message-broker";
import { link } from "common/es/link";
import Screen from "common/es/screen";
import BookIcon from "@valraiso-esf/esf-icons/es/book";
import EsfIcon from "@valraiso-esf/esf-icons/es/esf";
import MenuCard from "@valraiso-esf/esf-ui/es/menucard";
import CampaignIcon from "@valraiso-esf/esf-icons/es/campaign";
import CalendarIcon from "@valraiso-esf/esf-icons/es/calendar-month";
import TodayIcon from "@valraiso-esf/esf-icons/es/today";
import Button from "@valraiso-esf/esf-ui/es/button";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import Notification from "common/es/notification/notification";
import NotificationApp from "common/es/notification/notification-app";
import * as NotificationPermission from "common/es/notification/notification-app-permission";

import css from "./home.module.css";

export default function Home() {
  const [state] = App.useGlobalState();
  const navigate = Router.useNavigate();
  const { user, signed, signin } = Auth.useAuth();
  const [toast, setToast] = React.useState<boolean | undefined>(undefined);
  const ejectToast = Toast.eject(setToast);
  const { notification } = state.permissions;
  const [notificationPermission, setNotificationPermission] =
    React.useState<NotificationPermission.Permission>(notification);

  const ecole = state.ecole?.code;
  const { data } = Query.useQuery(
    ["navigation-menu", state.ecole?.code, user],
    () => Api.fetchMenu(ecole)
  );

  const hasActualites = data?.navigation.some(
    (item) => item.path === "/actualites"
  );
  const hasAgenda = data?.navigation.some((item) => item.path === "/agenda");
  const hasTraces = data?.navigation.some(
    (item) =>
      item.path === "/page/traces-le-webmagazine" ||
      item.children?.some(
        (child) => child.path === "/page/traces-le-webmagazine"
      )
  );

  React.useEffect(() => {
    if (Broker.isNativeApp()) {
      Broker.sendToNative({
        type: "permission",
        payload: { type: "notification" },
      });
    }
  }, []);

  // Native :
  // Permet de charger l'acceptation des notifications lorsque l'utilisateur
  // ne s'est pas encore prononcé.
  // Cela permet de garder monté le composant <NotificationApp />,
  // même après l'interaction de l'utilisateur, afin que le composant puisse terminer
  // le traitement de l'abonnement au notif.
  React.useEffect(() => {
    if (
      notificationPermission.canAskAgain === undefined &&
      notificationPermission.status === undefined &&
      notification.status &&
      notification.canAskAgain
    ) {
      setNotificationPermission(notification);
    }
  }, [notification, notificationPermission, user?.login]);

  const handleSignin = (e: React.MouseEvent) => {
    e.preventDefault();
    signin();
  };

  const showToast = () => {
    ejectToast(true);
  };

  const renderSigned = () => (
    <>
      {state.ecole && (
        <Router.Link
          to={`/annuaire-esf/${state.ecole.code}`}
          className={css.link}
        >
          <MenuCard icon={BookIcon} title="Annuaire" active>
            Consulter la fiche et l'annuaire des moniteurs de{" "}
            <b>{Format.capitalize(state.ecole.name)}</b>
          </MenuCard>
        </Router.Link>
      )}
      {hasTraces && (
        <Router.Link to="/page/traces-le-webmagazine" className={css.link}>
          <MenuCard icon={EsfIcon} title="Traces" active>
            Retrouvez ici tous les épisodes de
            <b> Traces - le webmagazine</b>
          </MenuCard>
        </Router.Link>
      )}
      {hasActualites && (
        <Router.Link to={`/actualites`} className={css.link}>
          <MenuCard icon={CampaignIcon} title="Actualités" active>
            Soyez au courant des actualités de votre école, du syndicat et du
            proshop
          </MenuCard>
        </Router.Link>
      )}
      {hasAgenda && (
        <Router.Link to={`/agenda`} className={css.link}>
          <MenuCard icon={CalendarIcon} title="Agenda" active>
            Ne ratez aucun des événements organisés pour vous
          </MenuCard>
        </Router.Link>
      )}
      {collectPlannings(data?.navigation).map((planning) =>
        planning.disabled ? (
          <div key={planning.path} onClick={showToast} className={css.link}>
            {renderPlanning(planning)}
          </div>
        ) : (
          renderPlanning(planning)
        )
      )}
    </>
  );

  const renderPlanning = (planning: NavigationNode) => (
    <MenuCard
      key={planning.path}
      icon={TodayIcon}
      title={planning.label}
      onClick={() => link(navigate, planning.path, true)}
      active
      disabled={planning.disabled}
      className={css.link}
    >
      Accéder directement à votre planning
    </MenuCard>
  );

  const renderUnsigned = () => (
    <>
      <Router.Link to="/annuaire-esf" className={css.link}>
        <MenuCard icon={BookIcon} title="Annuaire écoles" active>
          Consulter l'annuaire de toutes les <b>esf</b>
        </MenuCard>
      </Router.Link>
    </>
  );

  const displayNotification =
    Broker.isWebApp() &&
    "Notification" in window &&
    window?.Notification?.permission === "default";

  const displayExpoNotification =
    user?.login === "ABU" &&
    Broker.isNativeApp() &&
    NotificationPermission.isUndetermined(notificationPermission);

  return (
    <Screen title="Carnet Rouge" className={css.screen}>
      {data && (
        <>
          <div className={css.cardsOuter}>
            {signed && (displayNotification || displayExpoNotification) && (
              <div className={css.notification}>
                <span className={css.notificationTitle}>Notifications</span>
                {displayNotification && <Notification />}
                {displayExpoNotification && <NotificationApp />}
                <div className={css.notificationDescription}>
                  Les ESF et le SNMSF utilisent le système de notifications du
                  Carnet Rouge pour communiquer avec vous, que ce soit pour des
                  informations générales mais également en cas de perturbations
                  sur le système.
                </div>
              </div>
            )}
            <div className={css.cards}>
              {signed ? renderSigned() : renderUnsigned()}
            </div>
          </div>
          {!signed && (
            <div className={css.connexion}>
              <div className={css.hint}>
                Connectez-vous pour accéder à toutes vos fonctionnalités Carnet
                Rouge
              </div>
              <Button
                title="Connexion"
                tag="a"
                href="/"
                onClick={handleSignin}
                className={css.button}
              >
                Connexion
              </Button>
            </div>
          )}
          {toast && (
            <Toast seconds={2.5} variant="warning">
              Service momentanément indisponible
            </Toast>
          )}
        </>
      )}
    </Screen>
  );
}

function collectPlannings(navigations?: NavigationNode[]): NavigationNode[] {
  if (!navigations) return [];
  const plannings: NavigationNode[] = [];
  for (const node of navigations) {
    const path = node.path;
    if (isPlanningPath(path)) {
      plannings.push(node);
    }
    if (node.children) {
      plannings.push(...collectPlannings(node.children));
    }
  }
  return plannings;
}

function isPlanningPath(path?: string) {
  if (!path) return false;
  return (
    path.includes("/EsfWebMobile/Planning") ||
    path.includes("/EsfWebMobile/Authentification/Auth") ||
    path.includes("/EsfWeb/PosteMoniteur/Planning") ||
    path.includes("/EsfWebPlanningParticulier/PPLogin.aspx")
  );
}
