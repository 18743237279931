import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import classNames from "classnames";
import Switch from "@valraiso-esf/esf-ui/es/switch";
import LoaderCircle from "@valraiso-esf/esf-ui/es/loader-circle";
import * as Auth from "oauth2";
import * as Config from "configuration";
import * as Query from "react-query";
import * as Api from "./api";
import css from "./notification.module.css";
const api = Config.get("apiNotification");
const publicVapidKey = "BOvhLUIq4trgYjfRDJzc2rDiWGd3MuVKPC1-ZxiH4kxlSKKdI6v7tDW9h7dgsxqSepjdxyfgxux3EOoJgpG2YiM";
export default function Notification() {
    const { user } = Auth.useAuth();
    const [permission, setPermission] = React.useState(window.Notification.permission);
    const [subscription, setSubscription] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const { data: subscriptions, refetch } = Query.useQuery(["subscriptions", user?.login], () => Api.fetchSubscriptions());
    React.useEffect(() => {
        const getSubscription = async () => {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.getSubscription();
            if (subscription) {
                setSubscription(subscription);
            }
        };
        getSubscription();
    }, []);
    const alreadySubscribed = React.useMemo(() => subscriptions?.pwa.some((pwa) => pwa.endpoint === subscription?.endpoint), [subscriptions, subscription]);
    if (!("Notification" in window)) {
        return null;
    }
    async function subscribe() {
        if ("serviceWorker" in navigator) {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
            });
            const url = api + "/api/notification/subscribe";
            const response = await Auth.fetch(url, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(subscription),
            });
            if (response) {
                setSubscription(subscription);
                refetch();
            }
        }
    }
    async function unsubscribe() {
        if ("serviceWorker" in navigator) {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.getSubscription();
            if (!subscription) {
                return;
            }
            const { endpoint } = subscription;
            const url = api + "/api/notification/unsubscribe";
            const response = await Auth.fetch(url, {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ endpoint }),
            });
            if (response) {
                await subscription.unsubscribe();
                setSubscription(undefined);
                refetch();
            }
        }
    }
    const notificationsChanged = async () => {
        if (!loading) {
            setLoading(true);
            let result = permission;
            if (result !== "granted") {
                result = await askPermission();
                setPermission(result);
            }
            if (result === "granted") {
                if (alreadySubscribed) {
                    await unsubscribe();
                }
                else {
                    await subscribe();
                }
            }
            setLoading(false);
        }
    };
    const switchClasses = classNames(css.switch, { [css.loading]: loading });
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: switchClasses, children: [_jsx(Switch, { checked: permission === "granted" && alreadySubscribed, disabled: permission === "denied", onChange: notificationsChanged, className: css.switchInput, children: "J'accepte de recevoir les notifications du Carnet Rouge sur cet appareil" }), loading && (_jsx("div", { className: css.loader, children: _jsx(LoaderCircle, {}) }))] }), permission === "denied" && (_jsxs("div", { className: css.help, children: [_jsx("b", { children: "Vous avez refus\u00E9 les notifications" }), _jsx("br", {}), "Pour les accepter \u00E0 nouveaux, vous devez modifier les permissions dans votre navigateur"] }))] }));
}
async function askPermission() {
    const permission = await new Promise((resolve) => {
        const result = window.Notification.requestPermission(resolve);
        if (result)
            result.then(resolve);
    });
    return permission;
}
// Copied from the web-push documentation
function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
