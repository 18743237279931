import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as Ripple from "./ripple";
import css from "./menucard.module.css";
import classNames from "classnames";
export default function MenuCard({ icon: Icon, title, className, active, disabled, onMouseDown, children, ...props }) {
    const mousedowned = (e) => {
        if (active) {
            Ripple.create(e, "var(--color-secondary)", 0.05);
        }
        if (onMouseDown) {
            onMouseDown(e);
        }
    };
    const classes = classNames(className, css.outer, {
        [css.active]: active,
        [css.disabled]: disabled,
    });
    return (_jsxs("div", { ...props, className: classes, onMouseDown: mousedowned, children: [Icon && (_jsx("div", { className: css.icon, children: _jsx(Icon, { width: 24, height: 24 }) })), (title || children) && (_jsxs("div", { className: css.content, children: [title && _jsx("h2", { children: title }), _jsx("div", { className: css.inner, children: children })] }))] }));
}
