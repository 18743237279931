import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classnames from "classnames";
import * as React from "react";
import * as Ripple from "./ripple";
import css from "./button.module.css";
const Button = React.forwardRef(({ tag = "button", color = "primary", variant = "filled", size = "M", className, onMouseDown, onKeyDown, iconLeft, iconRight, children, ...props }, ref) => {
    const down = React.useRef(false);
    const classes = classnames(className, css.button, {
        [css.sizeS]: size === "S",
        [css.filled]: variant === "filled",
        [css.outlined]: variant === "outlined",
        [css.text]: variant === "text",
        [css.icon]: variant === "icon",
        [css.primary]: color === "primary",
        [css.secondary]: color === "secondary",
        [css.tertiary]: color === "tertiary",
        [css.success]: color === "success",
        [css.warning]: color === "warning",
        [css.error]: color === "error",
    });
    const mousedowned = (e) => {
        e.stopPropagation();
        Ripple.create(e, "var(--color-grey-lightest)");
    };
    const keydowned = (e) => {
        e.stopPropagation();
        if (!down.current && (e.code === "Space" || e.code === "Enter")) {
            Ripple.create(e, "var(--color-grey-lightest)");
        }
        down.current = true;
    };
    const keyup = (e) => {
        e.stopPropagation();
        down.current = false;
    };
    const Tag = tag;
    return (_jsxs(Tag, { ...props, tabIndex: tag === "a" ? 0 : undefined, className: classes, onMouseDown: mousedowned, onKeyDown: keydowned, onKeyUp: keyup, 
        // @ts-ignore
        ref: ref, children: [iconLeft && _jsx("span", { className: css.iconLeft, children: iconLeft }), children, iconRight && _jsx("span", { className: css.iconRight, children: iconRight })] }));
});
export default Button;
